<div class="container" id="page-title" *ngIf="pageTitle">
  <div class="row w-100">
    <div class="col-md">
      <h1>{{ pageTitle}}</h1>
    </div>
    <div class="col-md-auto mt-2 mt-md-0">
      <div class="d-flex justify-content-end align-items-center">
        <a class="breadcrumb-item" routerLink="/">Início</a>
        <ng-container *ngFor="let item of menuItems; let i = index">
          <em class="fa fa-chevron-right fa-sm mx-2 text-gray"></em>
          <a class="breadcrumb-item" [routerLink]="item.url"> {{ item.label }} </a>

        </ng-container>
      </div>
    </div>
  </div>
</div>
