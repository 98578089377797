import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfilIdentificadorEnum } from 'src/app/core/enums/perfil-identificador.enum';
import { Util } from 'src/app/shared/utils/util';

declare var $: any;

@Component({
  selector: 'app-sidebar-auth',
  templateUrl: './sidebar-auth.component.html',
  styleUrls: ['./sidebar-auth.component.scss']
})
export class SidebarAuthComponent implements OnInit, AfterContentChecked {

  usuarioSession = null;

  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
    $(document).ready(() => {
      $('[data-widget="treeview"]').Treeview('init');
      $('.menu-item').click(() => {
        $('.nav-icon').toggleClass('open');
      });
    });
    // TODO - retirar linha comentada
    this.getCurrentUserSession();
  }

  ngAfterContentChecked() {
    this.activeSideBar();
  }

  activeSideBar() {
    const baseUrl = window.location.origin;
    const url = window.location.href.replace(baseUrl, '');

    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).removeClass('active').find('i.far.fas').removeClass('fas');

    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).removeClass('active');

    // for treeview
    $('ul.nav-treeview a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).parentsUntil('.nav-sidebar > .nav-treeview')
      .removeClass('menu-open')
      .prev('a')
      .removeClass('active');

    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).addClass('active');

    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).find('i:not(.no-child)').addClass('fas text-secondary');

    // for treeview
    $('ul.nav-treeview a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).parentsUntil('.nav-sidebar > .nav-treeview')
      .addClass('menu-open')
      .prev('a')
      .addClass('active');
  }

  getCurrentUserSession() {
    this.usuarioSession = Util.getUsuarioSession();
  }

  isAdm() {
    return this.usuarioSession.perfis.find((x) => x.identificador === PerfilIdentificadorEnum.ADM) ?? false;
  }

  isSupport() {
    return this.usuarioSession.perfis.find((x) => x.identificador === PerfilIdentificadorEnum.SUPN1) ?? false;
  }

  logout() {
    this.router.navigate(['/auth/logout']);
  }

}
