
export const APP_VERSION = {
    version: 'v1.13.0',
    data: '11/04/2024'
};

export const MSG_SUCCES = 'Operação realizada com sucesso.';
export const MSG_FALIELD = 'Falha na operação.';
export const MSG_QUESTION_DELETE = 'Deseja Excluir?';
export const MSG_DELETE = 'Deseja excluir';
export const MSG_QUESTION_INACTIVATE = 'Deseja Inativar?';
export const MSG_QUESTION_ACTIVATE = 'Deseja Ativar?';
