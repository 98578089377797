<div class="wrapper">
  <!-- Componente de Sidebar -->
  <app-sidebar-auth></app-sidebar-auth>
  <!-- End Componente de Sidebar -->
  <div>
    <!-- Componente de Navbar -->
    <app-navbar-auth></app-navbar-auth>
    <!-- End Componente de Navbar -->

    <div class="content-wrapper">
      <!-- Content header -->
      <!-- <div class="content-header"></div> -->
      <!-- End Content Header -->

      <!-- Content -->
      <div class="content mt-3">

        <app-page-title></app-page-title>
        <router-outlet></router-outlet>
      </div>
      <!-- End Content -->
    </div>

    <!-- Componente end Footer -->
    <!-- <app-footer-auth></app-footer-auth> -->
    <!-- Componente de Footer-->

  </div>
</div>
