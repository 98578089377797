import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
// import { NotificacaoListDto } from '../dtos/notificacao/notificacao-list.dto';
// import { NotificacaoReadDto } from '../dtos/notificacao/notificacao-read.dto';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  private controller = 'Notificacao';

  constructor(public apiService: ApiService) { }

  // getByFilter(params?: URLSearchParams) {
  //   const filters = params ? `?${params.toString()}` : '';
  //   return this.apiService.get(`${this.controller}${filters}`).pipe(map(response => response.body.data.map(item => new NotificacaoListDto(item))));
  // }

  // read(obj: NotificacaoReadDto) {
  //   return this.apiService.patch(`${this.controller}`, obj);
  // }
}
