import { FilterComponent } from './components/filter/filter.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertCustomComponent } from './components/alert-custom/alert-custom.component';
import { LoadingCustomInterceptor } from './components/loading-custom/config/loading-custom-interceptor';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { PaginatorCustomComponent } from './components/paginator-custom/paginator-custom.component';
import { TableBodyDirective } from './components/table-custom/config/table-body.directive';
import { TableEmptyDirective } from './components/table-custom/config/table-empty.directive';
import { TableHeaderDirective } from './components/table-custom/config/table-header.directive';
import { TableCustomComponent } from './components/table-custom/table-custom.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { DisableOnClickDirective } from './directives/disable-on-click.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { UploadOneFileComponent } from './components/upload-one-file/upload-one-file.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UploadManyFilesComponent } from './components/upload-many-files/upload-many-files.component';
import { PickMonthComponent } from './components/pick-month/pick-month.component';
import { CalendarModule } from 'primeng/calendar';
import { PickDayComponent } from './components/pick-day/pick-day.component';
import {ProgressBarModule} from 'primeng/progressbar';

import { ModalCustomComponent } from './components/modal-custom/modal-custom.component';
import { CardComponent } from './components/card/card.component';
import { FieldComponent } from './components/field/field.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AutoCompleteModule } from 'primeng';

@NgModule({
  declarations: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    DisableOnClickDirective,
    DebounceClickDirective,
    UploadOneFileComponent,
    UploadManyFilesComponent,
    PickMonthComponent,
    PickDayComponent,
    ModalCustomComponent,
    CardComponent,
    PageTitleComponent,
    FilterComponent,
    FieldComponent,
    AutocompleteComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBorderRadius: '0px',
      fullScreenBackdrop: true,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      primaryColour: '#464c52',
      secondaryColour: '#343a40',
      tertiaryColour: '#5f676e'
    }),
    ReactiveFormsModule,
    CalendarModule,
    ProgressBarModule,
    AutoCompleteModule,
  ],
  exports: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    DisableOnClickDirective,
    DebounceClickDirective,
    UploadOneFileComponent,
    UploadManyFilesComponent,
    PickMonthComponent,
    PickDayComponent,
    ModalCustomComponent,
    CardComponent,
    PageTitleComponent,
    FilterComponent,
    FieldComponent,
    AutocompleteComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingCustomInterceptor,
      multi: true
    }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
