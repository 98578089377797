import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

interface MenuItem {
  url: string;
  label: string;
  title?: string;
}

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit, OnDestroy {
  public menuItems: MenuItem[] = [];
  private _subscriptionRouter: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    this._subscriptionRouter =  this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(
        () =>
          (this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
      );
  }
  ngOnDestroy(): void {
    this._subscriptionRouter.unsubscribe();
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {

      const label = child.snapshot.data['breadcrumb'];
      const title = child.snapshot.data['title'] || label;
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      if (label) {
        breadcrumbs.push({ label, url, title });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  get pageTitle(): string {
    const lastUrl = this.menuItems[this.menuItems.length - 1];
    return lastUrl?.title || lastUrl?.label;
  }
}
