import { Component, OnInit } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { NotificacaoService } from 'src/app/core/services/notificacao.service';
import { SweetalertCustom } from 'src/app/shared/utils/sweetalert-custom';
import { Util } from 'src/app/shared/utils/util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar-auth',
  templateUrl: './navbar-auth.component.html',
  styleUrls: ['./navbar-auth.component.scss']
})
export class NavbarAuthComponent implements OnInit {

  usuarioSession: any;
  // private hubConnection: HubConnection;
  // notificacoes: NotificacaoListDto[] = [];
  // allNotifications: NotificacaoListDto[] = [];
  constructor(private notificacaoservice: NotificacaoService) { }

  ngOnInit(): void {
    this.getUsuarioSession();
    // this.createConnection();
    // this.registerOnServerEvents();
    // this.startConnection();
    // this.getNotificationNotRead();
  }

  getUsuarioSession() {
    this.usuarioSession = Util.getUsuarioSession();
  }

  // createConnection() {
  //   this.hubConnection = new HubConnectionBuilder()
  //     .withUrl(environment.notificationHubUrl)
  //     .build();
  // }

  // registerOnServerEvents(): void {
  //   this.hubConnection.on('ReceiveNotification', (data: any) => {
  //     this.notificacoes.unshift(new NotificacaoListDto(data));
  //     this.addNotification();
  //   });
  // }

  // startConnection(): void {
  //   this.hubConnection
  //     .start()
  //     .then(() => {
  //         this.connectToGroup(this.usuarioSession.id);
  //     })
  //     .catch(() => {
  //       setTimeout(function() { this.startConnection(); }, 5000);
  //     });
  // }

  // connectToGroup(userId: string) {
  //   this.hubConnection.invoke('ConnectToNotifications', userId);
  // }

  // addNotification() {
  //   const el = document.querySelector('.notification');
  //   const count = Number(el.getAttribute('data-count')) || 0;
  //   el.classList.remove('notify');
  //   setTimeout(() => {
  //     el.setAttribute('data-count', (count + 1).toString());
  //     el.classList.add('notify');
  //   }, 1000);

  //   if (count === 0){
  //       el.classList.add('show-count');
  //   }
  // }

  // getNotificationNotRead() {
  //   const params = Util.createFilterStatusActive();
  //   params.append('usuarioId', this.usuarioSession.id);
  //   params.append('visualizada',  'false');
  //   params.append('orderByDesc',  'true');
  //   this.notificacaoservice.getByFilter(params).subscribe(
  //     (response) => {
  //       this.notificacoes = response;
  //       this.updateNotificationIcon();
  //     }
  //   );
  // }

  // getAllNotification() {
  //   const params = Util.createFilterStatusActive();
  //   params.append('usuarioId', this.usuarioSession.id);
  //   params.append('quantidadeMaxima', '10');
  //   params.append('orderByDesc',  'true');
  //   this.notificacaoservice.getByFilter(params).subscribe(
  //     (response) => {
  //       this.allNotifications = response;
  //     }
  //   );
  // }

  // updateNotificationIcon() {
  //   if (this.notificacoes.length >= 0) {
  //     const el = document.querySelector('.notification');
  //     el.setAttribute('data-count', this.notificacoes.length.toString());
  //     const count = Number(el.getAttribute('data-count')) || 0;
  //     el.classList.remove('notify');
  //     el.classList.add('notify');
  //     if (count > 0){
  //       el.classList.add('show-count');
  //     } else {
  //       el.classList.remove('show-count');
  //     }
  //   }
  // }

  // readNotification(item) {
  //   SweetalertCustom.showAlertDetailConfirm('Notificação', item.mensagem).then(
  //     (result) => {
  //       if (result.value) {
  //         const entity = new NotificacaoReadDto();
  //         entity.notificacaoUsuarioId = item.id;
  //         entity.visualizada = true;
  //         this.notificacaoservice.read(entity).subscribe(
  //           () => {
  //             this.getNotificationNotRead();
  //           }
  //         );
  //       }
  //     }
  //   );
  // }

  // clearNotifications() {
  //   this.allNotifications = [];
  // }
}
