import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutAuthComponent } from './core/layouts/layout-auth/layout-auth.component';
import { LayoutNoAuthComponent } from './core/layouts/layout-no-auth/layout-no-auth.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: LayoutAuthComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        data: {breadcrumb: null}
      },
      {
        path: 'aplicacoes',
        loadChildren: () => import('./modules/aplicacao/aplicacao.module').then(m => m.AplicacaoModule),
        data: {breadcrumb: 'Aplicações'}
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./modules/usuario/usuario.module').then(m => m.UsuarioModule),
        data: {breadcrumb: 'Usuários'}
      }
    ]
  },
  {
    path: 'auth',
    component: LayoutNoAuthComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  }
];
