<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-light-primary elevation-4 sidebar-custom">
  <!-- Brand Logo -->
  <!-- <a class="brand-link" href="/">
    <img alt="Logo" class="brand-image img-circle elevation-1" src="assets/img/maranhao_icone.png" style="opacity: .8">
    <span class="brand-text font-weight-light">Auth</span>
  </a> -->
  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel">
      <div class="user-info">
        <div class="info">
          <img class="img-fluid mx-auto d-block" style="width: 100%; height: auto;" src="assets/img/logo-auth-2.png" alt="Logo da secretaria">
        </div>
      </div>
    </div>

    <div class="user-panel">
      <div class="user-info">
        <div class="info">
          <p class="name-user">{{ usuarioSession.nome }}</p>
          <span class="name-profile">{{ usuarioSession.descricao }} </span>
          <div class="buttons-actions">
            <button type="button" (click)="logout()" class="btn btn-sm btn-outline-primary">SAIR</button>
            </div>
        </div>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
        <li class="nav-item">
          <a [routerLink]="['/']" class="nav-link">
            <em class="nav-icon pi pi-home"></em>
            <p> Início </p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="isAdm()">
          <a href="javascript:void(0)" class="nav-link">
            <em class="nav-icon pi pi-th-large"></em>
            <p>
              Aplicações
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/aplicacoes']" class="nav-link">
                <em class="pi pi-folder nav-icon"></em>
                <p>Listar</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/aplicacoes/cadastrar']" class="nav-link">
                <em class="pi pi-folder nav-icon"></em>
                <p>Cadastrar</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview" *ngIf="isAdm() || isSupport()">
          <a href="javascript:void(0)" class="nav-link">
            <em class="nav-icon pi pi-users"></em>
            <p>
              Usuários
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">

            <li class="nav-item">
              <a [routerLink]="['/usuarios']" class="nav-link">
                <em class="pi pi-folder nav-icon"></em>
                <p>Listar</p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/usuarios/cadastrar']" class="nav-link">
                <em class="pi pi-folder nav-icon"></em>
                <p>Cadastrar</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
