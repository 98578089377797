import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Util } from 'src/app/shared/utils/util';
import { environment } from 'src/environments/environment';

const NAMES_PROJECT  = environment.namespaceProject;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Verificar se o usuário está logado na aplicação
    const token = localStorage.getItem(`${NAMES_PROJECT}.token`);

    if (token === null) {
      this.router.navigate(['/auth/']);
      return false;
    }

    const usuario = Util.getUsuarioSession();
    // this.isIncompleteRegistration(usuario);
    return true;
  }

  isIncompleteRegistration(usuario) {
    if (usuario !== null && !usuario.cadastroCompleto) {
      this.router.navigate(['/cadastro-incompleto']);
      return false;
    }
  }

}
