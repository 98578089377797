<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#">
        <i  class="fas fa-bars text-primary"></i>
      </a>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <!-- <li  class="nav-item dropdown user-menu">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
        <div class="container">
          <div class="notification"></div>
        </div>
      </a>
      <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="max-height: 276px; overflow: auto;"> -->
        <!-- Rotina de Notificação - Reutilizar caso necessário após criar os DTOs de notificação -->
        <!-- <span class="dropdown-item dropdown-header">{{notificacoes.length}} Notificações</span>
        <li class="list-group-item" (click)="readNotification(item)" *ngFor="let item of notificacoes">
          <div class="row">
            <div class="col-md-12">
              <i class="fas fa-file mr-2">
                <span class="message-notification text-muted text-sm text-custom">{{item.mensagem}}</span>
              </i>
            </div>
            <div class="col-md-12">
              <span class="float-right text-muted text-sm">{{item.dataCriacao}}</span>
            </div>
          </div>
        </li>
        <a class="nav-link">
          <span class="dropdown-item dropdown-footer" data-toggle="modal" data-target="#listarNotificacoesModal" (click)="getAllNotification()">Ver tudo</span>
        </a> -->
      <!-- </ul>
    </li> -->
    <!-- <li  class="nav-item dropdown user-menu">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
        <img alt="User Image" class="user-image img-circle elevation-2" src="assets/img/user-128x128.jpg">
        <span class="d-none d-md-inline text-dark">{{ usuarioSession?.nome }}</span>
        <i class="fas fa-chevron-down ml-1 text-dark"></i>
      </a>
      <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <li class="user-header bg-primary">
          <img alt="User Image"class="img-circle elevation-2" src="assets/img/user-128x128.jpg">
          <p>{{ usuarioSession?.nome }}<small *ngFor="let item of usuarioSession?.perfis">- {{ item.descricao }}</small></p>
        </li>
        <li class="user-footer">
          <a class="btn btn-primary btn-flat float-right" [routerLink]="['/auth/logout']" >Sair</a>
        </li>
      </ul>
    </li> -->
  </ul>
</nav>
